import sendReport from './sendReport';

function errorEvent(baseURL, csrfToken, message, source, row, column, error) {
  const errorData = {
    message,
    critical: `${column}/${row}/source:${source}`,
    timestamp: Date.now(),
    stack: error?.stack ? error.stack : null,
  };

  sendReport(errorData, { baseURL, csrfToken });
}

const errorEventConfig = (baseURL, csrfToken) =>
  errorEvent.bind(null, baseURL, csrfToken);

export default errorEventConfig;
