import React from 'react';
import { shape, string } from 'prop-types';

import { Style } from 'nordic/style';
import { Head as NordicHead } from 'nordic/head';
import Head from '@pog-ui-landings-library/head';

import { addScopeToHeadConfig } from '../utils/section-helpers';

const LandingHead = ({ head, scope }) => (
  <NordicHead>
    <Head {...addScopeToHeadConfig(head, scope)} noIndex={!head.index} />

    {/* Fonts */}
    <link
      href="https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff2"
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      href="https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff2"
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />

    <Style>
      {`@font-face {
          font-family:'Proxima Nova';
          font-weight:800;
          font-display:swap;
          font-style:normal;
          src:url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff2) format("woff2"),
              url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff) format("woff"),
              url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.ttf) format("truetype")
        }
        @font-face {
          font-family:'Proxima Nova';
          font-weight:700;
          font-display:swap;
          font-style:normal;
          src:url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff2) format("woff2"),
              url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff) format("woff"),
              url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.ttf) format("truetype")
        }`}
    </Style>
  </NordicHead>
);

LandingHead.propTypes = {
  head: shape({ title: string }).isRequired,
  scope: string,
};

export default LandingHead;
