import {
  FIXED_POSITIONING_SECTIONS,
  FIXED_POSITIONING_SECTIONS_LAZYMODE,
  GENERIC_COMPONENT_ALIAS_SECTIONS,
  LAZY_MODES,
} from '../../../utils/constants';

export const addScopeToHeadConfig = (config, scope) => ({
  ...config,
  title: `${scope && !/^(prod).+/i.test(scope) ? `${scope} - ` : ''}${
    config?.title
  }`,
});

export const getLazyMode = (type, desiredLazyMode) => {
  // returns whenIdle so these section does not depend on user actions (whenVisible requires the user scrolls to it)
  if (FIXED_POSITIONING_SECTIONS.includes(type)) {
    return FIXED_POSITIONING_SECTIONS_LAZYMODE;
  }

  return [LAZY_MODES.WHEN_IDLE, LAZY_MODES.WHEN_VISIBLE].includes(
    desiredLazyMode,
  )
    ? desiredLazyMode
    : 'whenVisible';
};

export const getSectionName = (type) => {
  if (GENERIC_COMPONENT_ALIAS_SECTIONS.includes(type)) {
    return 'generic-component';
  }

  return type;
};

export const getUniqueSectionTypes = (sections) =>
  Array.from(
    new Set(
      sections
        ?.filter((section) => !!section?.type)
        .map(({ type }) => getSectionName(type?.toLowerCase())),
    ),
  );

export const hasRemoteModules = (sections) =>
  sections
    ?.filter((section) => !!section?.type)
    .some(({ type }) => type?.toLowerCase() === 'remote-module');
