import { handleTrackMelidata, handleTrackGTM } from '../../../utils/tracking';

const handleTrackReportError = (error) => {
  // eslint-disable-next-line no-console
  console.error(error);
};

export const getTrackHandlers = (gtmViewConfig) => ({
  notifyTrack: (customTrackdata, calmTrackdata) => {
    try {
      const customMelidata = {
        enabled: customTrackdata.enabled,
      };

      if (customTrackdata.path && customTrackdata.event_data) {
        customMelidata.melidata = {
          path: customTrackdata.path,
          event_data: customTrackdata.event_data,
        };
      }

      handleTrackMelidata(customMelidata, calmTrackdata);
    } catch (error) {
      handleTrackReportError(error);
    }
  },
  notifyMultipleTracks: (customTrackdata, calmTrackdata) => {
    try {
      handleTrackMelidata(customTrackdata, calmTrackdata);
    } catch (error) {
      handleTrackReportError(error);
    }

    try {
      handleTrackGTM(customTrackdata.gtm, gtmViewConfig);
    } catch (error) {
      handleTrackReportError(error);
    }
  },
});
