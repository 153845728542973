import React from 'react';
import { arrayOf, bool, string } from 'prop-types';

import { Style } from 'nordic/style';

import { DEPRECATED_SECTIONS } from '../../../utils/constants';

const SectionStyles = ({ types, theme, isMobile }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {types
      ?.filter((type) => !DEPRECATED_SECTIONS.includes(type))
      .map((type) => (
        <Style
          key={type}
          href={
            isMobile
              ? `${type}-${theme}-mobile.css`
              : `${type}-${theme}-desktop.css`
          }
          critical
        />
      ))}
  </>
);

SectionStyles.propTypes = {
  types: arrayOf(string),
  theme: string,
  isMobile: bool,
};

export default SectionStyles;
