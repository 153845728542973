import React from 'react';

import { LazyHydrate } from 'nordic/lazy';

const lazyWrapper = (Component, type) => {
  if (type === 'whenVisible') {
    return <LazyHydrate whenVisible>{Component}</LazyHydrate>;
  }

  if (type === 'whenIdle') {
    return <LazyHydrate whenIdle>{Component}</LazyHydrate>;
  }

  if (type === 'ssrOnly') {
    return <LazyHydrate ssrOnly>{Component}</LazyHydrate>;
  }

  return Component;
};

export default lazyWrapper;
