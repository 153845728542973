import sendReport from './sendReport';

const consoleErrorOriginal = console.error;

const IGNORED_WARNINGS = [
  'Support for defaultProps will be removed',
  'Each child in a list should have a unique "key" prop',
  'Prop `%s` did not match. Server: %s Client: %s%s',
];

function errorConsole(baseURL, csrfToken, ...args) {
  consoleErrorOriginal(...args);

  const stackTrace = new Error().stack;

  function getFileName(stackTraceParam) {
    const match = /at\s.*\((.*\.js)/.exec(stackTraceParam);

    return match ? match[1] : 'Unknown';
  }

  function getMessages() {
    const argArray = Array.prototype.slice.call(args);

    if (argArray.length === 1) {
      return argArray[0];
    }

    return argArray.join('\n');
  }

  const warningMessage = getMessages();

  if (!IGNORED_WARNINGS.some((warning) => warningMessage.includes(warning))) {
    const errorData = {
      message: warningMessage,
      stack: stackTrace,
      file: getFileName(stackTrace),
      timestamp: Date.now(),
    };

    sendReport(errorData, { baseURL, csrfToken });
  }
}

const errorConsoleConfig = (baseURL, csrfToken) =>
  errorConsole.bind(null, baseURL, csrfToken);

export default errorConsoleConfig;
