import React, { useState } from 'react';
import { bool, shape, string } from 'prop-types';

import { Modal } from '@andes/modal';
import { Button } from '@andes/button';
import { Typography } from '@andes/typography';
import { Image } from 'nordic/image';
import sanitizeHtml from 'sanitize-html';

import { REBRAND_RESOURCE_SRC } from '../utils/constants';

const ModalRebrand = ({ isMobile, translations, hidden }) => {
  const [isVisible, setIsVisible] = useState(!hidden);

  const { title, description, confirmButton } = translations || {};

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div className="modal-rebrand">
      <Modal
        className="modal-rebrand"
        open={isVisible}
        title=" "
        type={isMobile ? 'card' : 'small'}
        actions={{
          primary: (
            <Button fullWidth={isMobile} type="button" onClick={handleClose}>
              {confirmButton}
            </Button>
          ),
        }}
        media={
          isMobile
            ? {
                asset: (
                  <Image
                    src={REBRAND_RESOURCE_SRC.MOBILE}
                    alt="Gif ilustrativo"
                    className="modal-rebrand__image"
                  />
                ),
              }
            : {}
        }
        onClose={handleClose}
      >
        {!isMobile && (
          <Image
            src={REBRAND_RESOURCE_SRC.DESKTOP}
            alt="Gif ilustrativo"
            className="modal-rebrand__image"
          />
        )}
        <div className="modal-rebrand__text">
          <Typography className="modal-rebrand__title" type="title" size="l">
            {title}
          </Typography>
          <Typography
            className="modal-rebrand__description"
            type="body"
            size="l"
            component="div"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(description, { allowedTags: ['p'] }),
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

ModalRebrand.propTypes = {
  isMobile: bool,
  translations: shape({
    title: string,
    description: string,
    confirmButton: string,
  }).isRequired,
  hidden: bool,
};

export default ModalRebrand;
