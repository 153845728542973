import { MELIDATA_DEFAULTS } from './constants';

const {
  NONE,
  PATH_EVENT,
  MODE: { CLEAN_SEND },
  VALID_PATH_CONVENTION_REGEX,
} = MELIDATA_DEFAULTS;

const getCalmEventDataProps = (viewCalmTrack, calmTrackdata) => {
  const { type, sectionId, sectionVersionId, conversion, element } =
    calmTrackdata;

  return {
    ...viewCalmTrack.event_data,
    module_referer: type || NONE,
    section_id: sectionId ? String(sectionId) : NONE,
    section_version_id: sectionVersionId ? String(sectionVersionId) : NONE,
    conversion,
    element,
  };
};

const handleCalmTrackMelidata = (viewCalmTrack, calmTrackdata, melidataFn) => {
  const data = {
    ...viewCalmTrack,
    path: PATH_EVENT,
    event_data: getCalmEventDataProps(viewCalmTrack, calmTrackdata),
  };

  melidataFn(CLEAN_SEND, 'event', data);
};

export const handleTrackMelidata = (customTrackdata, calmTrackdata) => {
  const { melidata: melidataFn, __PRELOADED_STATE__: state = {} } =
    window || {};

  if (melidataFn) {
    const viewTrackData = state.landingConfig?.tracking?.melidata || {};
    const { customTrack: viewCustomTrack, calmTrack: viewCalmTrack } =
      viewTrackData;

    const { melidata: customMelidata = {} } = customTrackdata;

    const validPathConvention = VALID_PATH_CONVENTION_REGEX.test(
      customMelidata?.path,
    );

    // If valid path convention skip calm track
    if (!validPathConvention) {
      handleCalmTrackMelidata(viewCalmTrack, calmTrackdata, melidataFn);
    }

    if (customTrackdata.enabled && Object.keys(customMelidata || {}).length) {
      const {
        experiments,
        path: customPath = '',
        event_data: customEventData = {},
      } = viewCustomTrack;

      const { reply_data: replyData, ...melidata } = customMelidata;
      const isInheritingPath = melidata.path.startsWith(customPath);

      const track = {
        ...melidata,
        event_data: {
          ...melidata.event_data,
        },
      };

      if (experiments) {
        track.experiments = experiments;
      }

      if (validPathConvention) {
        track.event_data = {
          ...track.event_data,
          ...getCalmEventDataProps(viewCalmTrack, calmTrackdata),
        };
      }

      if (
        replyData &&
        isInheritingPath &&
        Object.keys(customEventData).length
      ) {
        track.event_data = {
          ...track.event_data,
          ...customEventData,
        };
      }

      melidataFn(CLEAN_SEND, 'event', track);
    }
  }
};

export const handleTrackGTM = (gtmConfig, gtmViewConfig) => {
  const { reply_data: replyData, ...gtmData } = gtmConfig || {};
  const { dataLayer: gtmDataLayer } = window || {};

  if (gtmData && gtmDataLayer) {
    const gtmEventData = {
      ...(replyData ? { ...gtmViewConfig.dataLayer[0] } : {}),
      event: gtmData.event,
      ...gtmData.additional_data,
    };

    gtmDataLayer.push(gtmEventData);
  }
};
