import { datadogRum } from '@datadog/browser-rum';

export const ddRumScript = (ddrumConfig, landingInfo) => {
  try {
    datadogRum.init(ddrumConfig.init);

    datadogRum.setUser({ id: landingInfo.userId || 'unknown' });
    datadogRum.startSessionReplayRecording();
    datadogRum.setGlobalContext({
      ...(ddrumConfig.globalContext || {}),
      ...landingInfo,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error starting datadog rum');
  }
};
