import { MELIDATA_DEFAULTS } from '../../utils/constants';

const {
  MODE: { CLEAN_SEND },
} = MELIDATA_DEFAULTS;
const filterEventData = (eventData, filterList) => {
  // We are using JSON.parse(JSON.stringify(eventData)) so the array lose its referrence
  const eventDataList = JSON.parse(JSON.stringify(eventData));

  filterList.forEach((x) => {
    if (eventDataList[`${x}`]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete eventDataList[`${x}`];
    }
  });

  return eventDataList;
};

const getEventData = (data) =>
  Object.keys(data).reduce((initial, key) => {
    const value = data[`${key}`];

    if (value === null) {
      return initial;
    }

    return { ...initial, [key]: value };
  }, {});

export const sendEventTrack = async (melidataViewConfig, el) =>
  new Promise((resolve) => {
    const { __PRELOADED_STATE__: state, fbq, melidata: meliDataFn } = window;
    const { experiments } = melidataViewConfig;

    const customTrack =
      state.landingConfig?.tracking?.melidata?.customTrack || {};

    const internalTrack = el.getAttribute('data-internal-tracking')
      ? el.getAttribute('data-internal-tracking')
      : null;

    const trackLabel =
      el.getAttribute('data-track-name') ||
      el.getAttribute('data-track-name') === ''
        ? el.getAttribute('data-track-name')
        : '';

    if (fbq) {
      fbq('track', trackLabel);
    }

    if (meliDataFn && internalTrack && Object.keys(customTrack).length) {
      let trackPath = customTrack?.path || '/pog/landings';

      const internalTrackProduct =
        el.getAttribute('data-internal-track-product') || null;
      const internalTrackLanding =
        el.getAttribute('data-internal-track-landing') || null;
      const internalTrackAction = el.getAttribute('data-event-action') || null;
      const internalTrackLocation =
        el.getAttribute('data-event-location') || null;
      const internalTrackPath = el.getAttribute('data-internal-tracking-path')
        ? el.getAttribute('data-internal-tracking-path')
        : '';
      const internalOutPath = el.getAttribute('data-internal-out-path') || null;
      const eventDataFilter =
        el.getAttribute('data-event-track-filters') || null;

      const elementTrackingFullPath = el.getAttribute('data-full-path');

      if (internalOutPath) {
        trackPath = internalOutPath;
      }

      const eventData = {
        ...getEventData({
          product: internalTrackProduct,
          action: internalTrackAction,
          location: internalTrackLocation,
          landing: internalTrackLanding,
        }),
      };
      const melidataEventObject = {
        path:
          elementTrackingFullPath ||
          `${trackPath}/${internalTrackPath}${trackLabel}`,
        ...(Object.keys(eventData).length !== 0 && {
          event_data: eventData,
        }),
      };

      try {
        const experimentsJson = JSON.parse(experiments);

        if (experiments) {
          melidataEventObject.experiments = experimentsJson;
        }
        // eslint-disable-next-line no-empty
      } catch {}

      const eventDataGlobal = customTrack?.event_data || {};

      const isInheritingPath = melidataEventObject.path.startsWith(
        customTrack.path,
      );

      let customEventData = {};

      if (eventDataFilter) {
        customEventData = {
          ...filterEventData(eventDataGlobal, eventDataFilter.split(',')),
        };
      } else if (isInheritingPath) {
        customEventData = {
          ...customTrack.event_data,
          ...melidataEventObject.event_data,
        };
      } else {
        customEventData = {
          ...melidataEventObject.event_data,
        };
      }

      const track = {
        path: melidataEventObject.path,
        event_data: customEventData,
        experiments,
      };

      meliDataFn(CLEAN_SEND, 'event', track);
    }

    resolve();
  });

const onTrackClick = async (e, melidataViewConfig, dataTrackElement) => {
  e.preventDefault();

  const trackName = dataTrackElement.getAttribute('data-track-name');

  // Logic performed before sending tracks
  // Prevents re-processing buttons
  if (
    dataTrackElement.classList.contains('active') &&
    trackName !== 'shortcuts'
  ) {
    return Promise.resolve();
  }

  return sendEventTrack(melidataViewConfig, dataTrackElement);
};

export const initEventTracking = (melidataViewConfig) => {
  document.getElementById('s-landing').addEventListener(
    'click',
    (e) => {
      // e.target was the clicked element
      const dataTrackEl = e.target.closest('[data-tracking]');

      if (dataTrackEl) {
        // Logic performed after sending tracks
        onTrackClick(e, melidataViewConfig, dataTrackEl)
          .then(() => {
            const isWebView = dataTrackEl.getAttribute('data-webview');
            const elURL = dataTrackEl.href;
            const target = dataTrackEl.target || '_self';

            if (elURL?.length) {
              const shouldActivate = !(
                'trackRepetitive' in dataTrackEl.dataset &&
                dataTrackEl.dataset.trackRepetitive === 'true'
              );

              if (shouldActivate) {
                dataTrackEl.classList.add('active');
              }

              if (isWebView) {
                window.location = elURL;
              } else {
                window.open(elURL, target);
              }
            }
          })
          .catch(() => {});
      }
    },
    true,
  );
};
